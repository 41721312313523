import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-company", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "primary",
                icon: "mdi-office-building",
                title: _vm.company.CompanyName,
              },
            },
            [
              _vm.edit
                ? _c(
                    VTabs,
                    {
                      on: { change: _vm.reset_alert },
                      model: {
                        value: _vm.tabs,
                        callback: function ($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs",
                      },
                    },
                    [
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("basic")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("financial")))]),
                      _c(VTab, [
                        _vm._v(_vm._s(_vm.$t("investment_conditions"))),
                      ]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("media")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("market_news")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("team")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("highlights")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("company_analysis")))]),
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("files")))]),
                      _vm.company.Presentation != null
                        ? _c(VTab, [
                            _vm._v(_vm._s(_vm.$t("company_presentation"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("ManageCompanyBasic", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabs == 0,
                    expression: "tabs == 0",
                  },
                ],
                attrs: {
                  company_prop: _vm.company,
                  roundBriefing: _vm.roundBriefing,
                },
                on: { alert: _vm.show_alert, saved: _vm.basic_saved },
              }),
              _c("ManageCompanyFinancial", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 1,
                    expression: "edit && tabs == 1",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
                on: {
                  alert: _vm.show_alert,
                  overview: _vm.change_financial_overview,
                  reset: _vm.update_analysis,
                },
              }),
              _c("ManageCompanyInvestmentConditions", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 2,
                    expression: "edit && tabs == 2",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  Partners: _vm.partners,
                  company_prop: _vm.company,
                },
                on: { alert: _vm.show_alert },
              }),
              _c("ManageCompanyMedia", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 3,
                    expression: "edit && tabs == 3",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
              }),
              _c("ManageCompanyNews", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 4,
                    expression: "edit && tabs == 4",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
              }),
              _c("ManageCompanyTeam", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 5,
                    expression: "edit && tabs == 5",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
              }),
              _vm.company.Id
                ? _c("ManageCompanyHighlights", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs == 6,
                        expression: "tabs == 6",
                      },
                    ],
                  })
                : _vm._e(),
              _c("ManageCompanyAnalysis", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 7,
                    expression: "edit && tabs == 7",
                  },
                ],
                attrs: {
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
                on: { reset: _vm.update_analysis },
              }),
              _c("ManageCompanyFiles", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.edit && _vm.tabs == 8,
                    expression: "edit && tabs == 8",
                  },
                ],
                attrs: {
                  roundBriefing: _vm.roundBriefing,
                  BriefingId: _vm.briefingId,
                  company_prop: _vm.company,
                },
              }),
              _vm.edit && _vm.tabs == 9 && _vm.company.Presentation != null
                ? _c("ManageCompanyPresentation", {
                    attrs: {
                      BriefingId: _vm.briefingId,
                      company_prop: _vm.company,
                    },
                  })
                : _vm._e(),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }